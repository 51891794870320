<template>
  <div class="header">
    <div class="desktop-header">
      <div class="upper-header">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <router-link class="logo" to="/"><img src="../assets/images/logo.png"/></router-link>
            </div>
            <div class="col-lg-9 text-lg-end">
              <div class="upper-rightheader">
                <div class="d-flex">
                  <div class="header-contact-box">
                    <div class="header-contact-icn"><i class="fas fa-map-marker-alt"></i></div>
                    <address class="header-contact-text">
                      <span>Address</span>
                      <router-link to="/contact">55 Main Street, Toronto, Ontario</router-link>
                    </address>
                  </div>
                  <div class="header-contact-box">
                    <div class="header-contact-icn"><i class="fas fa-phone-alt"></i></div>
                    <div class="header-contact-text">
                      <span>Call us Now</span>
                      <a href="tel:14583621258">+1-458-362-1258</a>
                    </div>
                  </div>
                  <div class="header-contact-box">
                    <div class="header-contact-icn"><i class="fas fa-envelope"></i></div>
                    <div class="header-contact-text">
                      <span>Send your questions to</span>
                      <a href="mailto:yourcompany@support.com">yourcompany@support.com</a>
                    </div>
                  </div>
                </div>
                <a href="#rqst-form" class="common-btn"><i class="far fa-comments"></i> Get a Free Estimate</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="down-header">
				<div class="container">
					<div class="navigation">
            <nav>
              <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/about">About</router-link>
                  <ul>
                    <li><router-link to="/page"> About Carpet  </router-link></li>
                    <li><router-link to="/page"> Carpet Products  </router-link></li>
                    <li><router-link to="/page"> Carpet Inspiration Gallery  </router-link></li>
                    <li><router-link to="/page"> Carpet Installation  </router-link></li>
                    <li><router-link to="/page"> Carpet Care & Maintenance  </router-link></li>
                  </ul>
                </li>
                <li class="megamenu-oparetor caret-icn"><router-link to="/product">Product</router-link></li>
                <li><router-link to="/service">Service</router-link>
                  <ul>
                    <li><router-link to="/page">installation</router-link></li>
                    <li><router-link to="/page">staining</router-link></li>
                    <li><router-link to="/page">Commercial flooring</router-link></li>
                    <li><router-link to="/page">Cleaning</router-link></li>
                    <li><router-link to="/page">Design Consultaion</router-link></li>
                  </ul>
                </li>
                <li><router-link to="/contact">Contact</router-link></li>
              </ul>
            </nav>
            <div class="megamenu">	
              <div class="mega-menu-inner">	
                <div class="megamenu-box">
                  <h6><a href="product.html">Flooring</a></h6>
                  <ul>
                    <li><router-link to="/page">Hardwood</router-link></li>
                    <li><router-link to="/page">Tile</router-link></li>
                    <li><router-link to="/page">Carpet</router-link></li>
                    <li><router-link to="/page">Laminat</router-link></li>
                  </ul>											
                </div>
                <div class="megamenu-box">
                  <h6><router-link to="/page">Walls</router-link></h6>
                  <ul>
                    <li><router-link to="/page">Wallpaper</router-link></li>
                    <li><router-link to="/page">Tile</router-link></li>
                  </ul>											
                </div>
                <div class="megamenu-box">
                  <h6><router-link to="/page">Countrtops</router-link></h6>
                  <ul>
                    <li><router-link to="/page">Marbel</router-link></li>
                    <li><router-link to="/page">Concrete</router-link></li>
                  </ul>											
                </div>
                <div class="megamenu-box">
                  <h6><router-link to="/page">Windows</router-link></h6>
                  <ul>
                    <li><router-link to="/page">Casement Windows</router-link></li>
                      <li><router-link to="/page">Picture Windows</router-link></li>
                    </ul>											
                </div>
                <div class="megamenu-box">
                  <h6><router-link to="/page">Fireplaces</router-link></h6>
                  <ul>
                    <li><router-link to="/page">Gas Fireplaces</router-link></li>
                      <li><router-link to="/page">Electric Fireplaces</router-link></li>
                    </ul>											
                </div>
              </div>
            </div>
            <div class="search-area">
              <input type="search" placeholder="Search">
              <input type="submit">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-header">	
      <div class="searchicin">
        <i class="fas fa-search"></i>
      </div>
      <div class="hide-srcharea">
        <input type="search" placeholder="Search">	
      </div>
      <div class="container">	
        <div class="headerinner">	
          <a href="#rqst-form" class="common-btn">Get a Free Estimate</a>
          <a href="javascript:void(0);" class="logo"><img src="../assets/images/logo.png" alt=""></a>
          <div class="right-mobile-head">	
              <a href="javascript:void(0);" class="search-area-mobile"><img src="../assets/images/mblsrchicn.png" alt=""> <input type="Search" class="input-srch" placeholder="search"></a>
            <a href="javascript:void(0);" class="toggle" id="openNav"><img src="../assets/images/togglr-img.png" alt=""></a>
          </div>
        </div>
      </div>
    </div>
    <div id="mySidenav" class="sidenav">
      <div class="mobile-menu-inner">
        <a href="javascript:void(0);" class="closebtn" id="closeNav">&times;</a>
        <ul>
            <li> <router-link to="/">Home</router-link></li>	
            <li class="active caret-menu"> <router-link to="/product"> Products</router-link>
              <ul class="submenu">
                <li class="parent-li"><a href="javascript:void(0);" class="second-level-submenu-oparetor"> Flooring   </a>
                  <ul class="second-level-submenu">
                    <li><a href="javascript:void(0);">Hardwood</a></li>
                    <li><a href="javascript:void(0);">Tile</a></li>
                    <li><a href="javascript:void(0);">Carpet</a></li>
                    <li><a href="javascript:void(0);">Laminate</a></li>
                  </ul>
                </li>
                <li class="parent-li"><a href="javascript:void(0);" class="second-level-submenu-oparetor"> Walls   </a>
                    <ul class="second-level-submenu">
                    <li><a href="javascript:void(0);">Wallpaper</a></li>
                    <li><a href="javascript:void(0);">Tile</a></li>
                  </ul>
                </li>
                <li class="parent-li"><a href="javascript:void(0);" class="second-level-submenu-oparetor"> Countrtops  </a>
                  <ul class="second-level-submenu">
                    <li><a href="javascript:void(0);">Marbel</a></li>
                    <li><a href="javascript:void(0);">Concrete</a></li>
                  </ul>
                </li>
                <li class="parent-li"><a href="javascript:void(0);" class="second-level-submenu-oparetor"> Windows   </a>
                  <ul class="second-level-submenu">
                    <li><a href="javascript:void(0);">Casement Windows</a></li>
                    <li><a href="javascript:void(0);">Picture Windows</a></li>
                  </ul>
                </li>
                <li class="parent-li"><a href="javascript:void(0);" class="second-level-submenu-oparetor"> Fireplaces    </a>
                  <ul class="second-level-submenu">
                    <li><a href="javascript:void(0);">Gas Fireplaces</a></li>
                    <li><a href="javascript:void(0);">Electric Fireplaces</a></li>
                  </ul>
                </li>
              </ul>
              <span class="sub-menu-oparetor"><img src="../assets/images/mbl-menu-caret.png" alt=""></span>
            </li>	
            <li class="caret-menu full-width-submenu"> <router-link to="/service">Services </router-link>
                <ul class="submenu">
                  <li><a href="javascript:void(0);">installation</a></li>
                  <li><a href="javascript:void(0);">staining</a></li>
                  <li><a href="javascript:void(0);">Commercial flooring</a></li>
                  <li><a href="javascript:void(0);">Cleaning</a></li>
                  <li><a href="javascript:void(0);">Design Consultaion</a></li>
                </ul>
                <span class="sub-menu-oparetor"><img src="../assets/images/mbl-menu-caret.png" alt=""></span>
            </li>	
            <li class="caret-menu full-width-submenu"> <router-link to="/about">About Us</router-link>
              <ul class="submenu">
                <li><a href="javascript:void(0);"> About The Store   </a></li>
                <li><a href="javascript:void(0);"> Owners   </a></li>
                <li><a href="javascript:void(0);"> History  </a></li>
                <li><a href="javascript:void(0);"> FAQ    </a></li>
                <li><a href="javascript:void(0);"> Testimonials  </a></li>
              </ul>
              <span class="sub-menu-oparetor"><img src="../assets/images/mbl-menu-caret.png" alt=""></span>
            </li>	
            <li> <router-link to="/contact">Contact Us</router-link></li>
        </ul>
        <div class="mobilee-address-box">
          <h2>Send your questions to</h2>
          <a href="mailto:yourcompany@support.com">yourcompany@support.com</a>
        </div>	
        <div class="mobilee-address-box">
          <h2>Send your questions to</h2>
          <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
        </div>
        <div class="mobilee-address-box">
          <h2>Address</h2>
          <router-link to="/contact"> 55 Main Street, Toronto, Ontario</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

 import $ from "jquery";

export default {
  name: 'Header',
  props: {
    msg: String
  },
  mounted: function(){
      $(".searchicin").click(function(){
        $(this).closest(".mobile-header").find(".hide-srcharea").slideToggle();
      });
      $('#openNav').click(function(){
        $('#mySidenav').css('width', '100%');
      });
      $('#closeNav').click(function(){
        $('#mySidenav').css('width', '0%');
      });
      $(".sub-menu-oparetor").click(function(){
      $(".sub-menu-oparetor").parents("li").find("ul.submenu").not().stop().slideUp();
      $(this).parents("li").find("ul.submenu").stop().slideToggle();
        $(".sub-menu-oparetor").parents("li").find("ul.submenu").find("ul").hide();
      $(this).parents("li").find("ul").find("ul").hide();
      $(".second-level-submenu-oparetor").closest("li.parent-li").removeClass("submenu-active");
      });

      $("li.megamenu-oparetor").mouseover(function(event){
        event.stopPropagation();
        $(this).closest(".navigation").find(".megamenu").fadeIn();
      });

      $(".megamenu").mouseover(function(event){
        event.stopPropagation();
      });
      $(document).mouseover(function(){
        $(".navigation").find(".megamenu").fadeOut();
      });
      $(".navigation ul li").find("ul").parent().addClass("caret-icn");

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
