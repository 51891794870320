<template>
  <section class="main testimonial-section">
    <div class="testimonial-imgsection"><img src="../assets/images/testimonial-main-img.jpg" alt=""></div>
    <div class="container">
      <div class="testimonialtext-sec">
        <h2>Customer <span>Testimonials</span></h2>
        <p>What people are say <span>About Us...</span></p>
        <div class="testimonal-crsl-box">
          <div class="testimonal-crsl" >
            <VueSlickCarousel v-bind="settings">
              <div class="item">
                <h5>Lance Jarvis</h5>
                    <h6>Lance Jarvis</h6>
                    <div class="testimonial-icn"><img src="../assets/images/qoutation-img.png" alt=""></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis augue iaculis mauris imperdiet tristique. Nulla vitae sagittis leo. Donec vulputate placerat eros et eleifend. Curabitur venenatis augue iaculis mauris imperdiet tristique. Etiam non lacus condimentum fermentum tortor. In interdum, purus sed molestie vestibulum, enim augue euismod nisi.</p>
              </div>
              <div class="item">
                <h5>Lance Jarvis</h5>
                    <h6>Lance Jarvis</h6>
                    <div class="testimonial-icn"><img src="../assets/images/qoutation-img.png" alt=""></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis augue iaculis mauris imperdiet tristique. Nulla vitae sagittis leo. Donec vulputate placerat eros et eleifend. Curabitur venenatis augue iaculis mauris imperdiet tristique. Etiam non lacus condimentum fermentum tortor. In interdum, purus sed molestie vestibulum, enim augue euismod nisi.</p>
              </div>
              <div class="item">
                <h5>Lance Jarvis</h5>
                    <h6>Lance Jarvis</h6>
                    <div class="testimonial-icn"><img src="../assets/images/qoutation-img.png" alt=""></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis augue iaculis mauris imperdiet tristique. Nulla vitae sagittis leo. Donec vulputate placerat eros et eleifend. Curabitur venenatis augue iaculis mauris imperdiet tristique. Etiam non lacus condimentum fermentum tortor.In interdum, purus sed molestie vestibulum, enim augue euismod nisi.</p>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>	
  </section>
  
    
  
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Testimonials',
  props: {
    msg: String
  },
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 2000,
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
