<template>
  <div class="home">
    <Header/>
    <Banner/>
    <HomeService/>
    <HomeProduct/>
    <HomeWhoWeAre/>
    <SeeOurProducts/>
    <GetStarted/>
    <Testimonials/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
// @ is an alias to /src
import Header from '../components/Header.vue'
import Banner from '../components/Banner.vue'
import HomeService from '../components/HomeService.vue'
import HomeProduct from '../components/HomeProduct.vue'
import HomeWhoWeAre from '../components/HomeWhoWeAre.vue'
import SeeOurProducts from '../components/SeeOurProducts.vue'
import GetStarted from '../components/GetStarted.vue'
import Testimonials from '../components/Testimonials.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner,
    HomeService,
    HomeProduct,
    HomeWhoWeAre,
    SeeOurProducts,
    GetStarted,
    Testimonials
  }
}
</script>
