<template>
  <section class="main">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-xl-6">
          <div class="who-we-are-img">
            <div class="who-firstimg">
              <img src="../assets/images/who-first.jpg" alt="">
            </div>
            <div class="who-secondimg">
              <img src="../assets/images/who-second.jpg" alt="">
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="who-section-text">
            <div class="section-heading text-left who-section-heading">
                  <h2><span>Who</span> We Are</h2>
                </div>
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here. </p>
              <p>Making it look like readable English. Many desktop publishing 
            packages and web page editors now use Lorem Ipsum as 
            their default.</p>
            <router-link to="/about" class="common-midium-button">Learn More </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>	
</template>

<script>

export default {
  name: 'HomeWhoWeAre',
  props: {
    msg: String
  },
  components: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
