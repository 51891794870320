<template>
  <section class="main readyto-contectsec-main">
    <div class="container">
      <div class="readyto-contectsec">
        <div class="ready-to-get-image">
          <img src="../assets/images/readyimg.jpg" alt="">
        </div>
        <div class="ready-to-text-sec">
          <h2>Ready to  <span>Get Started?</span></h2>
          <p>Here's everything you need to know when starting your next flooring project.</p>
          <a href="javascript:void(0);" class="common-mini-button">Get Started </a>
        </div>
      </div>	
    </div>
  </section>
</template>

<script>

export default {
  name: 'GetStarted',
  props: {
    msg: String
  },
  components: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
