<template>
  <div class="homeProduct">
    <section class="main product-area" title="Carpet Tile">
      <div class="container">
        <div class="section-heading">
          <h2>Our <span>Products</span></h2>
        </div>
        <div class="product-inner">
          <div class="row justify-content-center">
            <div class="col-xl-3 col-6">
              <router-link to="/product" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/prdct1.jpg" alt="">
                </div>
                <div class="product-text"><h6>Carpet</h6></div>
              </router-link>
            </div>
            <div class="col-xl-3 col-6">
              <router-link to="/product" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/prdct2.jpg" alt="">
                </div>
                <div class="product-text"><h6>Hardwood</h6></div>
              </router-link>
            </div>
            <div class="col-xl-3 col-6">
              <router-link to="/product" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/prdct3.jpg" alt="">
                </div>
                <div class="product-text"><h6>Laminate</h6></div>
              </router-link>
            </div>
            <div class="col-xl-3 col-6">
              <router-link to="/product" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/prdct4.jpg" alt="">
                </div>
                <div class="product-text"><h6>Tile</h6></div>
              </router-link>
            </div>
            <div class="col-xl-3 col-6">
              <router-link to="/product" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/prdct5.jpg" alt="">
                </div>
                <div class="product-text"><h6>Luxury vinyl </h6></div>
              </router-link>
            </div>
            <div class="col-xl-3 col-6">
              <router-link to="/product" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/prdct6.jpg" alt="">
                </div>
                <div class="product-text"><h6>vinyl</h6></div>
              </router-link>
            </div>
            <div class="col-xl-3 col-6">
              <router-link to="/product" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/prdct7.jpg" alt="">
                </div>
                <div class="product-text"><h6>Carpet Tile</h6></div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="text-center">
          <router-link to="/product" class="common-midium-button">Browse Products</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'HomeProduct',
  props: {
    msg: String
  },
  components: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
