<template>
  <div class="banner">
    <VueSlickCarousel v-bind="settings">
      <div class="item">
        <img src="../assets/images/banner1.jpg">
        <div class="banner-block-area">
          <div class="container">
            <div class="banner-block-inner">
              <div class="row align-items-center">
                <div class="col-xl-8">
                  <h3>See Your Room Come To Life</h3>
                  <p>Book an appointment with one of our flooring experts to get started with your next project!</p>
                </div>
                <div class="col-xl-4 text-xl-right">
                  <a href="javascript:void(0);" class="common-big-button">Ready to get started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <img src="../assets/images/banner1.jpg">
        <div class="banner-block-area">
          <div class="container">
            <div class="banner-block-inner">
              <div class="row align-items-center">
                <div class="col-xl-8">
                  <h3>See Your Room Come To Life</h3>
                  <p>Book an appointment with one of our flooring experts to get started with your next project!</p>
                </div>
                <div class="col-xl-4 text-xl-right">
                  <a href="javascript:void(0);" class="common-big-button">Ready to get started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Banner',
  props: {
    msg: String
  },
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 2000,
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
