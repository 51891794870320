<template>
  <div class="homeService">
    <section class="main">
      <div class="container">
        <div class="heading">
          <h1>We Offer a Variety of Services for <span>Your Flooring Project</span></h1>
          <p>We <span>Offer Several Services</span> to help you complete your project from start to finish</p>
        </div>
        <div class="flooring-img-section">
          <div class="row">
            <div class="col-xl-3 col-md-12">
              <a href="javascript:void(0);" class="flooring-box">
                <div class="flooring-img">
                  <img src="../assets/images/floor-img1.jpg" alt="">
                </div>
                <div class="flooring-text">
                  <h5>Installation</h5>
                </div>
              </a>
            </div>
            <div class="col-xl-3 col-md-12">
              <a href="javascript:void(0);" class="flooring-box">
                <div class="flooring-img">
                  <img src="../assets/images/floor-img2.jpg" alt="">
                </div>
                <div class="flooring-text">
                  <h5>In-home Estimates</h5>
                </div>
              </a>
            </div>
            <div class="col-xl-3 col-md-12">
              <a href="javascript:void(0);" class="flooring-box">
                <div class="flooring-img">
                  <img src="../assets/images/floor-img3.jpg" alt="">
                </div>
                <div class="flooring-text">
                  <h5>Commercial Flooring</h5>
                </div>
              </a>
            </div>
            <div class="col-xl-3 col-md-12">
              <a href="javascript:void(0);" class="flooring-box">
                <div class="flooring-img">
                  <img src="../assets/images/floor-img4.jpg" alt="">
                </div>
                <div class="flooring-text">
                  <h5>Design Consultation</h5>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="text-center">
          <router-link to="/service" class="common-midium-button">View All Services</router-link>
        </div>
      </div>
    </section>
    <section>
      <div class="get-professional">
        <div class="container">
          <div class="get-professional-iner">
            <p>Get Professional Advice</p>
            <a href="tel:+1-458-362-1258" class="tell-us-now">Call us Now. +1-458-362-1258</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'HomeService',
  props: {
    msg: String
  },
  components: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
