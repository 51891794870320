<template>
<div>
  <section class="main featured-section">
    <div class="container">
      <h3>Featured Brands</h3>
      <div class="featured-logo-crsl" >
        <VueSlickCarousel v-bind="SliderSettings">
          <div class="item">
            <a href="javascript:void(0);" class="brand-img"><img src="../assets/images/brnd1.png" alt=""></a>
          </div>
          <div class="item">
            <a href="javascript:void(0);" class="brand-img"><img src="../assets/images/brnd2.png" alt=""></a>
          </div>
          <div class="item">
            <a href="javascript:void(0);" class="brand-img"><img src="../assets/images/brnd3.png" alt=""></a>
          </div>
          <div class="item">
            <a href="javascript:void(0);" class="brand-img"><img src="../assets/images/brnd4.png" alt=""></a>
          </div>
          <div class="item">
            <a href="javascript:void(0);" class="brand-img"><img src="../assets/images/brnd5.png" alt=""></a>
          </div>
          <div class="item">
            <a href="javascript:void(0);" class="brand-img"><img src="../assets/images/brnd6.png" alt=""></a>
          </div>
          <div class="item">
            <a href="javascript:void(0);" class="brand-img"><img src="../assets/images/brnd3.png" alt=""></a>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
  <section class="main request-section">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-xl-5">
          <div class="request-lft-sec">
            <h3>We carry products from several major brands that we've selected to offer you a wide array of options.</h3>
            <p>Estibulum accumsan ullamcorper sem, quis mollis nulla congue ut. Donec pulvinar leo eget leo iaculis hendrerit est tempus.</p>
            <div class="colling-area">
              <p>Questions? Call us!</p>
              <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
            </div> 
          </div>
        </div>
        <div class="col-xl-6">
          <div class="request-rgt-sec" id="rqst-form">
            <h3>Request Estimate</h3>
            <p>Let one of our experts help your find the perfect floor</p>
            <form action="">
              <div class="two-row">
                <div class="frm-input">
                  <input type="text" placeholder="Your Name:" required>
                </div>
                <div class="frm-input">
                  <input type="email" placeholder=" Your Email:" required>
                </div>
              </div>
              <div class="thrd-row">
                <div class="frm-input">
                  <input type="text" placeholder="Your Telephone No:">
                </div>
                <div class="thrd-row-dinr">
                  <div class="frm-input">
                  <input type="text" placeholder="City:">
                  </div>
                  <div class="frm-input">
                    <input type="text" placeholder=" State:">
                  </div>
                </div>
              </div>
              <div class="one-row">
                <div class="frm-input">
                  <textarea placeholder="Tell Us What you are Looking For:" required></textarea>
                  </div>
              </div>
              <div class="one-row">
                <button class="submit" type="submit">Get Estimate <i class="fas fa-paper-plane"></i></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="footer">
    <footer class="footer">
      <div class="container">
        <div class="upper-footer">
          <div class="row">
            <div class="col-xl-2 col-md-3 foot-border">
              <div class="footerbx fst-footer-bx">
                <h4 class="accrodian-oparetor">COMPANY</h4>
                <div class="accordian-box">
                  <ul>
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/about">About</router-link></li>
                    <li><router-link to="/product">Product</router-link></li>
                    <li><router-link to="/service">Service</router-link></li>
                    <li><router-link to="/contact">Contact</router-link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-4 foot-border">
              <div class="footerbx scnd-footer-bx">
                <h4 class="accrodian-oparetor">Products</h4>
                <div class="accordian-box">
                  <ul>
                    <li><router-link to="/page">Carpet</router-link></li>
                    <li><router-link to="/page">Hardwood</router-link></li>
                    <li><router-link to="/page">Laminate</router-link></li>
                    <li><router-link to="/page">Tile</router-link></li>
                    <li><router-link to="/page">Luxury Vinyl</router-link></li>
                    <li><router-link to="/page">Waterproof</router-link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-5 foot-border">
              <div class="footerbx thrd-footer-bx">
                <h4 class="accrodian-oparetor">GET IN TOUCH</h4>
                <div class="accordian-box">
                  <div class="foot-address-box">
                    <div class="foot-add-icn">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div class="foot-add-text">
                      <address><router-link to="/contact">A-4923 Lohnsburg 547 Nice <br>
                        St, San Diego,<br>
                        CA, USAMagetsham 19</router-link>
                      </address>
                    </div>
                  </div>
                  <div class="foot-address-box">
                    <div class="foot-add-icn">
                      <i class="fas fa-phone-alt"></i>
                    </div>
                    <div class="foot-add-text">
                      <p><a href="tel:+1-458-362-1258">+1-458-362-1258</a></p>
                    </div>
                  </div>
                  <div class="foot-address-box">
                    <div class="foot-add-icn">
                      <i class="fas fa-envelope"></i>
                    </div>
                    <div class="foot-add-text">
                      <p><a href="mailto:Contact@yoursite.web">Contact@yoursite.web</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-md-12 foot-border">
              <div class="footerbx frth-footer-bx">
                <div class="frth-footer-box-left">
                  <h4>Follow Us On</h4>
                  <div class="footer-social-icon">
                    <ul>
                      <li><a href="https://www.facebook.com/" target="_blank"><img src="../assets/images/foot-icn1.png" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="../assets/images/foot-icn2.png" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="../assets/images/foot-icn3.png" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="../assets/images/foot-icn4.png" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="../assets/images/foot-icn5.png" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="../assets/images/foot-icn6.png" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="../assets/images/foot-icn7.png" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="../assets/images/foot-icn8.png" alt=""></a></li>
                    </ul>
                  </div>
                </div>
                <div class="frth-footer-box-right">
                  <div class="payment-sec">
                    <h4>payment accepted</h4>
                    <img src="../assets/images/paymnt-img.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="down-footer">
          <div class="row align-items-center">
            <div class="col-xl-6">
              <div class="down-foot-left">
                <h6>Questions? Call us! <a href="tel:+1-458-362-1258">+1-458-362-1258 </a> <span class="dvdr">|</span> <span class="spon-text">Sponsored by: <img src="images/rovomp-img.png" alt=""></span></h6>
                <ul>
                  <li>© 2021 <span>Your company Name.</span> All rights reserved</li>
                  <li><a href="javascript:void(0);">Terms of Use</a></li>
                  <li><a href="javascript:void(0);">Privacy Policy.</a></li>
                </ul>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="down-foot-right text-lg-right">
                <ul>
                  <li>Sponsored by: <img src="../assets/images/rovomp-img.png" alt=""></li>
                  <li>Powered by: <img src="../assets/images/rovomp-img.png" alt=""></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div id="pagetop" class="fixed right-0" v-show="scY > 300" @click="toTop">
          <i class="fas fa-chevron-up"></i>
        </div>
      </transition>
    </footer>
  </div>
</div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

  import $ from "jquery";

export default {
  name: 'Footer',
  props: {
    msg: String
  },
  components: {
    VueSlickCarousel
  },
  
  mounted: function() {
    window.addEventListener('scroll', this.handleScroll);
    var wdth =$(document).width();
      if(wdth < 768 ){
        $(".accrodian-oparetor").click(function(){
          $(".accordian-box").not().stop().slideUp();
          $(this).next().stop().slideToggle();
        });
      }
  },
   methods: {
      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
    },
  data() {
    return {
      SliderSettings: {
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 2000,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        prevArrow: '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
        nextArrow: '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>',
        responsive:[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow:3,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow:2,
            }
          },
        ]
      },
      scTimer: 0,
      scY: 0,
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>