<template>
  <div>
    <section class="visualize-sec">
      <div class="container">
        <div class="visualize-innr">
          <div class="visualize-black-box">
            <h2>See Our Products in Your Space!</h2>
            <h3>New Floors In Your Current Room</h3>
            <a href="javascript:void(0);" class="get-started-link">Experience Our <span>Visualizer Tool</span>!</a>
          </div>
          <div class="pick-product-holder">
            <div class="pick-product-bx">
              <img src="../assets/images/pick1.png" alt="">
              <h6>Pick a product</h6>
            </div>
            <div class="pick-product-bx">
              <img src="../assets/images/pick2.png" alt="">
              <h6>Snap a picture</h6>
            </div>
            <div class="pick-product-bx">
              <img src="../assets/images/pick3.png" alt="">
              <h6>Transform your room!</h6>
            </div>
          </div>
          <div class="pick-area-mobile">
            <img src="../assets/images/pick-area-mblimg.png" alt="">
          </div>
        </div>
      </div>
    </section>
    <section class="pick-product-mobile">
      <div class="container">
        <div class="pick-product-holder">
          <div class="pick-product-bx">
            <img src="../assets/images/pick1.png" alt="">
            <h6>Pick a product</h6>
          </div>
          <div class="pick-product-bx">
            <img src="../assets/images/pick2.png" alt="">
            <h6>Snap a picture</h6>
          </div>
          <div class="pick-product-bx">
            <img src="../assets/images/pick3.png" alt="">
            <h6>Transform your room!</h6>
          </div>
        </div>
      </div>
    </section>	
  </div>
</template>

<script>

export default {
  name: 'SeeOurProducts',
  props: {
    msg: String
  },
  components: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
